<template>
    <div>
        <showAt breakpoint="large">
            <b-tabs pills card vertical class="nav-blocks">
                <b-tab :title="$t('settings.profile-information')" class="nav-small">

                    <b-card>
                        <b-card-title>
                            {{$t('settings.profile-information')}}
                        </b-card-title>

                        <b-tabs pills class="gray-nav">
                            <b-tab :title="$t('settings.main-information')">
                                <account-info />
                            </b-tab>
                            <b-tab :title="$t('settings.personal-information')">
                                <account-personal />
                            </b-tab>
                        </b-tabs>

                    </b-card>

                </b-tab>
                <b-tab :title="$t('settings.change-password')">
                    <account-password />
                </b-tab>
                <b-tab :title="$t('settings.login-history')">
                    <account-sessions />
                </b-tab>
                <b-tab :title="$t('settings.additionally')">
                    <account-settings />
                </b-tab>        

            </b-tabs>
        </showAt>
        <showAt breakpoint="mediumAndBelow">
            <div style="padding: 12px 16px;">
                <b-card v-for="(item, index) in mobileNav" :key="index" no-body v-ripple @click="navTo(item)" style="border-radius: 16px !important; margin-bottom: 12px;">
                    <div class="d-flex justify-content-between" style="padding: 20px 24px;">
                        <span style="font-weight: 600;">{{ $t(item.title) }}</span>
                        <div class="q-icon icon-chevron-right"></div>
                    </div>
                </b-card>
            </div>
        </showAt>
    </div>
</template>

<script>
    
    import User from "@/modules/user"

    import AccountSessions from "./account-sessions"
    import AccountInfo from "./account-info"
    import AccountPassword from "./account-password"
    import AccountSettings from "./account-settings"
    import AccountPersonal from "./account-personal"

    export default {

        data() {
            return {
                User,
                mobileNav: [
                   { title: "settings.profile-information", name: "user-account-info" },
                   { title: "settings.change-password", name: "user-account-password" },
                   { title: "settings.login-history", name: "user-account-sessions" },
                   { title: "settings.additionally", name: "user-account-settings" },
                ]
            }
        },
        methods: {
            navTo(item) {
                this.$router.push({
                    name: item.name
                });
            },
        },
        components: {
            AccountInfo,
            AccountPassword,
            AccountSessions,
            AccountSettings,
            AccountPersonal
        },
       
        watch: {

        },
        mounted() {

        }

    }

</script>