<template>
    <b-card no-body>
                   

        <b-card-body class="mb-0 pb-0">
            <b-card-title>{{$t('settings.active-sessions')}}</b-card-title>
            <b-card-text>
                <p class="font-weight-bolder" style="margin-bottom: 8px;">{{$t('settings.web-browsers')}}</p>
                <p class="text-muted">
                    {{$t('settings.these-web-browsers')}}
                </p>
            </b-card-text>
        </b-card-body>
        
        <showAt breakpoint="mediumAndAbove">
            <b-table-simple>
                <b-thead>
                    <b-tr>
                        <b-th>{{$t('tables.browser')}}</b-th>
                        <b-th>{{$t('tables.location')}}</b-th>
                        <b-th>{{$t('tables.last-activity')}}</b-th>
                        <b-th></b-th>
                    </b-tr>
                </b-thead>

                <b-tbody>
                    <b-tr v-if="current">
                        <b-th class="font-weight-normal text-black">
                            {{ current.browser }} в {{ current.device }}
                            <br>
                            <span class="text-muted font-small-1">{{ current.op_version }}</span>
                        </b-th>
                        <b-th class="font-weight-normal text-black">
                            {{ current.city }}, {{ current.country }} 
                            <br>
                            <span class="text-muted font-small-1">IP: {{ current.ip }}</span>
                        </b-th>
                        <b-th class="font-weight-normal text-black">
                            {{ get_last_activity(current.last_activity) }}
                        </b-th>
                        <b-th></b-th>
                    </b-tr>
                    <b-tr v-for="(session, index) in sessions" :key="index">
                        <b-th class="font-weight-normal text-black">
                            {{ session.browser }} в {{ session.device }}
                             <br>
                            <span class="text-muted font-small-1">{{ session.op_version }}</span>
                        </b-th>
                        <b-th class="font-weight-normal text-black">
                            {{ session.city }}, {{ session.country }}
                            <br>
                            <span class="text-muted font-small-1">IP: {{ session.ip }}</span>
                        </b-th>
                        <b-th class="font-weight-normal text-black">
                            {{ get_last_activity(session.last_activity) }}
                        </b-th>
                        <b-th>
                            <feather-icon icon="TrashIcon" @click="revoke_session_by_id( session )" style="cursor: pointer;" />
                        </b-th>
                    </b-tr>
                </b-tbody>

            </b-table-simple>    
        </showAt>
        
        <showAt breakpoint="small">
            <div>
                
                <template v-if="current" >
                    
                    <div class="group-header bordered-both d-flex justify-content-between" style="top: 0px;">
                        {{$t('settings.current-session')}}
                    </div>

                    <b-card class="mb-0" no-body style="padding: 16px;">
                        <div class="d-flex justify-content-between">
                            <span> {{ current.browser }} в {{ current.device }} {{ current.op_version }}</span>
                            <span>{{ get_last_activity(current.last_activity) }}</span>
                        </div>
                        <div style="margin-top: 6px;">
                            <span class="text-muted">
                                {{ current.ip }} • {{ current.city }}, {{ current.country }} 
                            </span>
                        </div>
                    </b-card>

                    <div class="separator"></div>
                    
                    <div v-ripple class="text-danger" @click="revoke_all_sessions" style="padding: 10px 16px; font-weight: 500;">
                        {{$t('settings.end-other-sessions')}}
                    </div>
                    
                    <div class="separator ml-0"></div>
                    
                    <div class="group-header text-muted" style="position: relative; font-weight: 400; font-size: 12px;">
                        {{$t('settings.sign-out-on-all-devices')}}
                    </div> 
                     
                </template>
                
                <div v-if="sessions.length > 0" class="group-header bordered d-flex justify-content-between" style="top: 0px;">
                    {{$t('settings.other-sessions')}}
                </div>
                
                <template v-for="(session, index) in sessions">
                    <b-card v-ripple @click="revoke_session_by_id(session)" class="mb-0" no-body style="padding: 16px;">
                        <div class="d-flex justify-content-between">
                            <span> {{ session.browser }} в {{ session.device }} {{ session.op_version }}</span>
                            <span>{{ get_last_activity(session.last_activity) }}</span>
                        </div>
                        <div style="margin-top: 6px;">
                            <span class="text-muted">
                                {{ session.ip }} • {{ session.city }}, {{ session.country }} 
                            </span>
                        </div>
                    </b-card>
                    <div :class="{
                        'separator' : true,
                        'ml-0' : sessions.length - 1 === index
                    }"></div>
                </template>
                
                <div v-if="sessions.length > 0" class="group-header text-muted" style="position: relative; font-weight: 400; font-size: 12px; border: none;">
                   {{$t('settings.click-end-it')}}
                </div> 
                
            </div>
        </showAt>
        
    </b-card>
</template>

<script>

    import User from "@/modules/user"
    import TimeAgo from 'javascript-time-ago'
    import ru from 'javascript-time-ago/locale/ru'
    import en from 'javascript-time-ago/locale/en.json'
    
    TimeAgo.addLocale(ru)
    TimeAgo.addLocale(en)
    TimeAgo.setDefaultLocale(en);

    export default {

        data() {
            return {
                User,
                current: null,
                sessions: [],
                timeAgo: new TimeAgo('en-US')
            }
        },
        methods: {
            
            revoke_all_sessions() {                
                
//                if( confirm("Вы действительно хотите завершить все сеасны, кроме текущего?") ) {
//                    this.$request.post("sessions.revokeAll");
//                    this.sessions = [];
//                }


                this.$bvModal.msgBoxConfirm('Вы действительно хотите завершить все сеасны, кроме текущего?', {
                    title: 'Подтвердите действие',
                    size: 'sm',
                    buttonSize: 'md',
                    okVariant: 'danger',
                    okTitle: "Завершить",
                    cancelTitle: "Оставить",
                    modalClass: 'dialog',
                    headerClass: 'p-2 border-bottom-0',
                    footerClass: 'p-2 border-top-0',
                    centered: true
                }).then(( value ) => {
                    if( value ) {
                        this.$request.post("sessions.revokeAll");
                        this.sessions = [];
                    }
                });

                
//                this.$swal({
//                    title: 'Подтвердите действие',
//                    text: "Вы действительно хотите завершить все сеасны, кроме текущего?",
//                    icon: 'info',
//                    showCancelButton: true,
//                    confirmButtonText: 'Завершить',
//                    cancelButtonText: "Отмена",
//                    buttonsStyling: true,
//                  }).then(result => {
//                    if (result.value) {
                       
//                    }
//                });
//                
               
            },
            
            revoke_session_by_id( item ) {
                
                this.$bvModal.msgBoxConfirm(`Вы действительно хотите завершить сеанс ${item.browser} ${item.device} ${item.op_version}?`, {
                    title: 'Подтвердите действие',
                    size: 'sm',
                    buttonSize: 'md',
                    okVariant: 'danger',
                    okTitle: "Завершить",
                    cancelTitle: "Оставить",
                    modalClass: 'dialog',
                    headerClass: 'p-2 border-bottom-0',
                    footerClass: 'p-2 border-top-0',
                    centered: true
                }).then(( value ) => {
                    if( value ) {
                        this.$request.post("sessions.revoke", {
                            id: item.id
                        }).then( result => {
                           let index = this.sessions.indexOf( item );
                           this.sessions.splice(index, 1);
                        }); 
                    }
                });
                
                               
            },
            
            getColorByType(type) {
                
                switch( type ) {
                    case 'Android': return 'success';
                    case 'Windows': return 'info';
                    case 'Mac': return 'primary';
                    case 'iPhone': return 'primary';
                    case 'Apple': return 'primary';
                    case 'Unknown': return 'danger';
                    default: return 'danger';
                }
                
            },
            
            getIconByType(type) {
                
                switch( type ) {
                    case 'Android': return 'phone';
                    case 'Windows': return 'laptop';
                    case 'Mac': return 'laptop';
                    case 'iPhone': return 'phone';
                    case 'Apple': return 'phone';
                    case 'Unknown': return 'file-x';
                    default: return 'file-x';
                }
                
            },
           
            get() {
                
                this.$request.get("sessions.get").then( result => {
                    this.current = result.current;
                    this.sessions = result.sessions;
                });
                
            },
            
            get_last_activity( timestamp ) {
                // return this.timeAgo.format(timestamp * 1000);
                return new TimeAgo(this.$i18n.locale == 'ru' ? 'ru-RU' : 'en-US').format( timestamp * 1000 ); 
            }
           
        },
        components: {

        },
        computed: {
            user() {
                return User.self;
            }
        },
        watch: {

        },
        mounted() {
            this.get();
        }

    }

</script>