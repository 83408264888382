<template>
    <b-card no-body>
        
        <b-card-header>
            <b-card-title>{{$t('settings.additionally')}}</b-card-title>
        </b-card-header>
   
               
        <b-list-group flush>
            <b-list-group-item class="flex-column align-items-start">
               
                <div class="d-flex w-100 justify-content-between">
                    <h5 class="mb-0">
                      {{$t('settings.two-factor-authentication')}}
                    </h5>
                    <div class="d-flex">
                        <b-form-checkbox
                            class="ml-1"
                            name="two-factor"
                            v-model="User.self.is_twofactor_enabled"
                            @change="update_settings('is_twofactor_enabled', $event)"
                            switch
                            style="right: 44px;"
                        />
                    </div>
                </div>
               
                <b-card-text class="text-muted" style="font-size: 12px;">
                   {{$t('settings.try-to-authorize-two-factor')}}
                </b-card-text>
                
            </b-list-group-item>
            
        </b-list-group>
        
    </b-card>
</template>

<script>

    import User from "@/modules/user/"
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

    export default {

        data() {
            return {
                User
            }
        },
        methods: {
            update_settings( key, value ) {
                this.$request.post('user.updateSettings', {
                    key: key,
                    value: value ? 1 : 0 
                });
                
                if( key === 'is_twofactor_enabled' ) {
                    if( value ) {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                              title: this.$t('toasts.titles.two-auth'),
                              text: this.$t('toasts.text.on-two-auth'),
                              icon: 'CheckCircleIcon',
                              variant: 'success',
                            }
                        });
                    } else {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                              title: this.$t('toasts.titles.two-auth'),
                              text: this.$t('toasts.text.off-two-auth'),
                              icon: 'AlertCircleIcon',
                              variant: 'danger',
                            }
                        });
                    }
                }
                
            }
        },
        components: {

        },
        watch: {

        },
        mounted() {

        }

    }

</script>